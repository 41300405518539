import React from "react";
import Layout from "../components/UI/layout";

import SEO from "../components/UI/seo";
import TerminisYCondiciones from "../components/Tyc/TerminosYcondiciones/TerminosYcondiciones";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";


const Tyc = () => {
  const {t} = useTranslation();
  return(
    <Layout>
      <SEO title="Codeicus | Terminos y Condiciones" />
      <TerminisYCondiciones/>
    </Layout>
    
  )
}

export default Tyc
  



  export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;