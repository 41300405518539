import React from 'react';
import * as styles from './TerminosYcondiciones.module.scss';



const Tyc = () => {
	return (
		<section className={styles.bodyTyc}>
			<div className={styles.contenerTitle} >
				<h3>POLÍTICA DE PRIVACIDAD. PROTECCION DE DATOS PERSONALES. UTILIZACIÓN DE COOKIES</h3>
				<h4>1. POLÍTICA DE PRIVACIDAD.</h4>
				<h5>1. PROTECCIÓN DE DATOS PERSONALES</h5>
				<p>
					Los Usuarios y visitantes se comprometen a navegar por el Sitio Web y a utilizar el contenido de buena fe.<br></br>
					Le informamos que: <br></br>
					1 - Los datos que suministre en el Sitio Web serán tratados con la máxima confidencialidad y pasarán a formar parte de la Base de Datos de titularidad de Codeicus, con domicilio en la calle Tucuman 1946, CABA, Argentina, para la gestión comercial que nos vincule, el envío a través de emails de sus pedidos o búsquedas de empleos o empleados, remitirle información sobre las los servicios que creamos de su interés, para gestionar las Cuentas Individuos y Empresa, responder sus consultas, contactarlo. Asimismo, le enviaremos información por mail sobre nuevos productos, funcionalidades, beneficios y otro tipo de información.<br></br>
					2 - No tiene obligación de suministrar sus datos personales, salvo que quiera utilizar los servicios del Sitio Web, dado que los mismo son indispensables para su individualización.<br></br>
					3 - El uso del Servicio implica la aceptación de esta política de privacidad, así como la autorización a la Empresa para que trate los datos personales que nos facilite.<br></br>
					4 - Ud. se encuentra legitimado para ejercer los derechos de acceso, rectificación y supresión de sus datos personales. Para ello, deberá acreditar su identidad.<br></br>
					Por la mera visita al Sitio Web, las personas que accedan a la misma no facilitan información personal alguna ni quedan obligadas a facilitarla.<br></br>
					El ordenador donde está alojado el Sitio Web utiliza cookies para mejorar el servicio prestado por la Empresa. Estas cookies se instalan automáticamente en el ordenador empleado por los Usuarios y visitantes, pero no almacenan ningún tipo de información relativa a éstos.<br></br>
					La Empresa presume que los datos han sido introducidos por su titular o por persona autorizada por éste, así como que son correctos y exactos.<br></br>
					Conforme a la legislación vigente en materia de protección de datos, la empresa ha adoptado los niveles de seguridad adecuados a los datos facilitados por los Usuarios y visitantes que ingresen datos personales y, además, ha instalado todos los medios y medidas a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y extracción de los mismos.<br></br>
					La empresa puede necesitar enviar información a sus proveedores, partners o empresas relacionadas para la prestación del Servicio, los que pueden encontrar fuera del país. El Usuario autoriza la transferencia de sus datos personales. Si fuese requerido por las leyes aplicables, el Usuario deberá firmar cualquier convenio adicional o enmienda que pueda requerirse para permitir la transferencia de los datos fuera del País, de conformidad con tales leyes aplicables.
				</p>					
				<h5>2. EJERCICIO DE DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN</h5>
				<p>	
					Datos de los Usuarios y Usuarios Suscriptores.
					Corresponde a los Usuarios la actualización de sus propios datos. En cualquier momento, el Usuario tendrá el derecho de acceso, rectificación y supresión de sus Datos Personales incluidos en los distintos formularios de alta. Para ejercitar esta facultad el Usuario deberá enviar un mail a info@codeicus.com indicando el dato que quiere eliminar, y en caso de modificación deberá indicar el dato registrado que debe ser actualizado, modificado o corregido. El Usuario es responsable de la veracidad y exactitud de los datos y la Empresa no será responsable de la inexactitud de los Datos Personales de los Usuarios.	
				</p>
				<h5>3. UTILIZACIÓN DE COOKIES</h5>	
				<p>
				Para prestar el servicio del Sitio Web es necesaria la utilización de cookies, pequeños ficheros de datos.
					Las cookies se utilizan con la finalidad de mejorar el servicio prestado por la Empresa pero no almacenan ningún tipo de información personal relativa a los usuarios, la información obtenida es anónima. Si usted lo desea puede configurar su navegador para ser avisado en pantalla de la recepción de cookies y para impedir la instalación de cookies en su disco duro. Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta información.

				</p>
			</div>
		</section>
	);
}


export default Tyc ;